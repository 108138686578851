import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);
export default {
  props: {
    content: Object,
    index: Number
  },

  data() {
    return {
      root: null,
      observer: null
    };
  },

  methods: {
    useScrollTo(offset, scrollElement, duration) {
      return new Promise(resolve => {
        console.log('SCROLL', scrollElement);
        gsap.to(scrollElement, {
          duration: duration / 1000,
          scrollTo: offset,
          onComplete: resolve
        });
      });
    }

  } // mounted() {
  //   this.root = this.$refs.root;
  //
  //   let options = {
  //     root: null, // default, use viewport
  //     threshold: 0,
  //     rootMargin: '0% 0% 00% 0%'
  //   };
  //
  //   let observerCallback = (entries, observer) => {
  //     entries.forEach((entry) => {
  //
  //       if (entry.isIntersecting) {
  //         this.$store.commit('setCurrentYear', parseInt(this.content.year))
  //         this.$store.commit('setCurrentID', this.content.id)
  //         this.$store.commit('setCurrentClass', this.content.class)
  //         this.$store.commit('setCurrentIndex', this.index)
  //
  //         console.log(this.content.id)
  //
  //       }
  //     });
  //   };
  //
  //   this.observer = new IntersectionObserver(observerCallback, options);
  //
  //   this.observer.observe(this.root);
  // },
  // beforeDestroy() {
  //   if (this.observer) this.observer.unobserve(this.root);
  // },
  // computed: {
  //   currentYearNav() {
  //     return this.$store.state.currentYearNav
  //   },
  //   isBlocked () {
  //     return this.$store.state.isBlocked
  //   }
  // },
  // watch: {
  //   currentYearNav() {
  //     if (this.year.toString() === this.currentYearNav.toString()) {
  //
  //       if (this.isBlocked) {
  //         return
  //       }
  //
  //       console.log('YEY', this.root.getBoundingClientRect().top)
  //       const offset = this.root.getBoundingClientRect().top + window.scrollY;
  //
  //       this.$store.commit('setIsBlocked', 1000)
  //       this.useScrollTo(offset, window, 1000)
  //     }
  //   }
  // }

};