export default {
  props: {
    data: Object,
    meta: Object
  },

  data() {
    return {
      transitionIndex: 'down',
      transitionYear: 'left'
    };
  },

  computed: {
    isProjects() {
      return this.$store.state.isProjects;
    },

    currentYear() {
      return this.$store.state.currentYear;
    },

    currentIndex() {
      return this.$store.state.currentIndex;
    }

  },
  watch: {
    currentYear(newVal, oldVal) {
      if (newVal > oldVal) {
        this.transitionYear = 'slide-left';
      } else {
        this.transitionYear = 'slide-right';
      }
    },

    currentIndex(newVal, oldVal) {
      if (newVal > oldVal) {
        this.transitionIndex = 'slide-up';
      } else {
        this.transitionIndex = 'slide-down';
      }
    }

  }
};