import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  home: "",
  class: "w-full h-full"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PageHome = _resolveComponent("PageHome");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_PageHome, {
    data: $props.data,
    meta: $props.meta
  }, null, 8, ["data", "meta"])]);
}