import { createStore } from 'vuex';

export default createStore({
  state: {
    isProjects: false,

    currentYear: 2023,
    currentID: null,
    currentClass: null,
    currentIndex: 0,

    meta: {},
  },
  mutations: {
    setIsProjects(state, boolean) {
      state.isProjects = boolean;
    },
    setCurrentYear(state, number) {
      state.currentYear = number;
    },
    setCurrentIndex(state, number) {
      state.currentIndex = number;
    },
    setMeta(state, object) {
      state.meta = object;
    },
  },
  actions: {},
  modules: {},
});
