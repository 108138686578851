import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  "basic-link": ""
};
const _hoisted_2 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createTextVNode(_toDisplayString($props.text ? `${$props.text} ` : ''), 1), _createElementVNode("a", {
    target: "_blank",
    href: $props.link,
    class: "pointer-events-auto underline"
  }, _toDisplayString($props.linktext ? $props.linktext : $props.link), 9, _hoisted_2), _createTextVNode(_toDisplayString($props.text ? '.' : ''), 1)]);
}