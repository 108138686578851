import { useRoute } from 'vue-router';
import { ref, onMounted, onUnmounted } from 'vue';
import axios from 'axios';
export default {
  data() {
    return {
      root: 'my-timeline-davqdfwknhe',
      data: [],
      isLoading: true,
      value: 2023,
      yearsArray: [],
      meta: {}
    };
  },

  setup() {
    function setViewport() {
      document.documentElement.style.setProperty('--vw', `${Math.floor(document.body.clientWidth) / 100}px`);
      document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
    }

    onMounted(() => {
      setViewport();
      window.addEventListener('resize', setViewport);
    });
    onUnmounted(() => {
      window.removeEventListener('resize', setViewport);
    });
    return {
      route: useRoute()
    };
  },

  methods: {
    fetchData(channelString) {
      axios.get(`https://api.are.na/v2/channels/${channelString}`).then(response => {
        if (response) {
          this.meta.updated_at = response.data.updated_at;
          this.meta.slug = response.data.slug;
          this.meta.owner = response.data.owner;
          let promises = [];
          let years = response.data.contents.filter(item => item.base_class === 'Channel').sort((a, b) => parseInt(a.title) > parseInt(b.title) ? 1 : -1);

          for (const year of years) {
            promises.push(axios.get(`https://api.are.na/v2/channels/${year.slug}`).then(response => {
              if (response) {
                this.data.push({
                  title: response.data.title,
                  contents: response.data.contents
                });
              } else {
                console.log('error');
              }
            }));
          }

          Promise.allSettled(promises).then(() => {
            let promises = [];

            for (const year in this.data) {
              for (const content of this.data[year].contents.filter(item => item.base_class === 'Channel')) {
                promises.push(axios.get(`https://api.are.na/v2/channels/${content.slug}`).then(resp => {
                  if (resp) {
                    console.log(resp.data);
                    content.contents = resp.data.contents;
                    content.title = resp.data.title;
                    content.year = this.data[year].title;
                  } else {
                    console.log('error');
                  }
                }));
              }

              for (const content of this.data[year].contents.filter(item => item.base_class === 'Block')) {
                content.year = this.data[year].title;
              }
            }

            Promise.allSettled(promises).then(() => {
              this.isLoading = false;
            });
            console.log(this.data);
          });
        } else {
          console.log('error');
        }
      });
    }

  },

  mounted() {
    this.fetchData(this.root);
  }

};