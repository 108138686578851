import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  "snippet-block": "",
  class: ""
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BasicImage = _resolveComponent("BasicImage");

  const _component_BasicText = _resolveComponent("BasicText");

  const _component_BasicLink = _resolveComponent("BasicLink");

  return _openBlock(), _createElementBlock("section", _hoisted_1, [$props.block.class === 'Image' ? (_openBlock(), _createBlock(_component_BasicImage, {
    key: 0,
    class: "w-full filter drop-shadow-custom object-contain object-left",
    style: _normalizeStyle({
      maxHeight: 'calc(100vh - 4rem)',
      maxWidth: '100%'
    }),
    image: {
      src: $props.block.image.large.url,
      srcset: `${$props.block.image.display.url} 200w, ${$props.block.image.large.url} 900w`
    }
  }, null, 8, ["style", "image"])) : _createCommentVNode("", true), $props.block.class === 'Text' ? (_openBlock(), _createBlock(_component_BasicText, {
    key: 1,
    text: $props.block.content_html
  }, null, 8, ["text"])) : _createCommentVNode("", true), $props.block.class === 'Link' ? (_openBlock(), _createBlock(_component_BasicLink, {
    key: 2,
    text: $props.isProject ? null : 'contributed code to',
    link: $props.block.source.url
  }, null, 8, ["text", "link"])) : _createCommentVNode("", true)]);
}