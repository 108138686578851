import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-68da64ba"), n = n(), _popScopeId(), n);

const _hoisted_1 = ["alt", "data-srcset", "data-src"];
export default {
  props: {
    image: Object
  },

  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("img", {
        "basic-image": "",
        draggable: false,
        alt: props.image ? props.image.alt : '',
        "data-srcset": props.image.srcset,
        "data-src": props.image.url,
        class: _normalizeClass(['w-full lazyload transition-all duration-hover', 'object-contain', 'opacity-0'])
      }, null, 8, _hoisted_1);
    };
  }

};