export default {
  props: {
    data: Object
  },

  data() {
    return {
      valueYear: 2023,
      valueIndex: 0,
      valuePage: 0,
      isHidden: false
    };
  },

  computed: {
    currentYear() {
      return this.$store.state.currentYear;
    },

    currentIndex() {
      return this.$store.state.currentIndex;
    },

    isProjects() {
      return this.$store.state.isProjects;
    },

    maxIndex() {
      return Math.max(...this.data.map(o => o.contents.length));
    },

    maxYear() {
      return Math.max(...this.data.map(o => parseInt(o.title)));
    },

    minYear() {
      return Math.min(...this.data.map(o => parseInt(o.title)));
    },

    currentYearData() {
      return this.data.filter(year => year.title == this.valueYear).length > 0 ? this.data.filter(year => year.title == this.valueYear)[0] : {
        contents: []
      };
    } // isBlocked() {
    //   return this.$store.state.currentYearScroll
    // }


  },
  watch: {
    // currentYearScroll() {
    //   this.valueYear = this.currentYearScroll
    // },
    // valueYear() {
    //   if (this.isBlocked) return
    //   this.$store.commit('setCurrentYearNav', this.valueYear)
    // },
    valuePage(newVal, oldVal) {
      this.$store.commit('setIsProjects', newVal == 0 ? false : true);
    },

    valueYear(newVal, oldVal) {
      this.valueIndex = 0;
      this.$store.commit('setCurrentYear', newVal);
      this.isHidden = true;
      setTimeout(() => {
        this.isHidden = false;
      }, 200);
    },

    valueIndex(newVal, oldVal) {
      this.$store.commit('setCurrentIndex', newVal);
    }

  }
};